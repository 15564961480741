import { Options, Vue } from "vue-class-component";
import backstretch from "@/components/background-images/backstretch/backstretch.vue";

@Options({
    name: "app-login",
    components: {
        "app-backstretch": backstretch,
    },
})
export default class backgroundImages extends Vue {
    public curIndex = 0;
    public backstretchElement: Array<HTMLElement> = [];
    public curBackgroundImage: Array<any> = [require("@/assets/images/bg/01.jpg"), require("@/assets/images/bg/01.jpg")];
    public mySetInterval = 0;
    public myIndex = 0;

    private backgroundImageList = [
        require("@/assets/images/bg/01.jpg"),
        require("@/assets/images/bg/02.jpg"),
        require("@/assets/images/bg/03.jpg"),
        require("@/assets/images/bg/04.jpg"),
        require("@/assets/images/bg/05.jpg"),
    ];

    public mounted(): void {
        this.backstretchElement[0] = document.getElementById("backstretch0") as HTMLElement;
        this.backstretchElement[1] = document.getElementById("backstretch1") as HTMLElement;
        this.curBackgroundImage[0] = require("@/assets/images/bg/01.jpg");
        this.curBackgroundImage[1] = require("@/assets/images/bg/01.jpg");
        this.curIndex = 1;
        this.setBackgroundImage();
    }

    public unmounted(): void {
        clearInterval(this.mySetInterval);
    }

    private setBackgroundImage() {
        this.mySetInterval = setInterval(() => {
            const size = this.backgroundImageList.length - 1;
            if (this.myIndex >= size) {
                this.myIndex = 0;
            } else {
                this.myIndex++;
            }
            const e0 = this.curIndex;
            const e1 = this.curIndex === 1 ? 0 : 1;
            this.backstretchElement[e0].classList.remove("animate__fadeIn", "in");
            this.backstretchElement[e0].classList.add("animate__fadeOut", "out");
            this.backstretchElement[e1].classList.remove("animate__fadeOut", "out");
            this.backstretchElement[e1].classList.add("animate__fadeIn", "in");
            this.curBackgroundImage[e1] = this.backgroundImageList[this.myIndex];

            setTimeout(() => {
                this.curIndex = e1;
                this.curBackgroundImage[e0] = this.backgroundImageList[this.myIndex];
            }, 1000);
        }, 10 * 1000);
    }

    get previewWidth() {
        return this.$windowWidth;
    }
    get previewHeight() {
        return this.$windowHeight;
    }
}
